import './home.scss';
import banner from '../../assets/images/geometric-landscape-futuristic-digital-blockchain-fintech-technology 1.png'
// import banner from '../../assets/images/banner.png'
import promodePic from '../../assets/images/Group 22632nnnnn.png'
import promodeSidePic from '../../assets/images/promod-side-face-pic.svg'
import followIcon from '../../assets/images/follow-icon.svg'
import tallModernBuilding from '../../assets/images/tall-modern-building.svg'
import carLogo from '../../assets/images/CryptoAssetRatting.png'
import cbwLogo from '../../assets/images//vivasvatRevolutionsLimited.png'
// import cbwLogo from '../../assets/images/cbw-logo.png'
import apexDefiLabs from '../../assets/images/APexDefiLabs.png'
import defiAssets from '../../assets/images/DefiAssetLLC.png'
import webIcon from '../../assets/images/web.svg'
import facebookIcon from '../../assets/images/facebook-icon.svg'
import linkedinIcon from '../../assets/images/linkedin-icon.svg'
import twitterIcon from '../../assets/images/twitter-icon.svg'
import linkedinFillIcon from '../../assets/images/linkedin-full-icon.png'
import mrPromodeMetHon from '../../assets/images/mr-promode-met-hon.png'
import pramodThumbnail from '../../assets/images/pramod-thumbnail.png'
import pramodSlider from '../../assets/images/pramod-slider.png'
import sliderImg5 from '../../assets/images/slider-img5.png'
import newSliderImg1 from '../../assets/images/new-img/Food-Drive-2022_1.png'
import newSliderImg2 from '../../assets/images/new-img/slider-img2.png'
import newSliderImg3 from '../../assets/images/new-img/slider-img3.png'
import newSliderImg4 from '../../assets/images/new-img/slider-img4.png'
import newSliderImg7 from '../../assets/images/new-img/slider-img7.png'
import newSliderImg8 from '../../assets/images/new-img/pramodewithseccom.png'
import linkedInBackground from '../../assets/images/backgroundPramode.png'
import speakerAtBiggestCapital from '../../assets/images/speaker-at-biggest-capital.png'
import meetingWithAssemblyman from '../../assets/images/meeting-with-assemblyman.png'
import meetPromodAttarde from '../../assets/videos/Meet-Pramod-Attarde.mp4'
import iotBanner from '../../assets/images/global-business-internet-network-connection-iot.svg'
import AboutImage from '../../assets/images/Group 22663 (1)cs.png'
import hexa from '../../assets/images/Group 22649.png'
import facebook from '../../assets/images/fbIcon.png'
import twitter from '../../assets/images/TwitterIcon.png'
import linkedin from '../../assets/images/linkedin.png'
import transformglobal from '../../assets/images/Transforming Global.png'
import React, { useState, useEffect } from 'react';
import GlobalEntrepreneurs from '../../assets/images/Global Entrepreneurs.png' 
import Slider from "react-slick";

function Home() {
    const [showDates, setShowDates] = useState(false);

    var settings = {
        arrows: true,
        dots: false,
        infinite: false,
        speed: 600,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: "200px",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
        ]
    };  

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        const dateOptions = document.getElementById('date-options');
        if (dateOptions) {
          setTimeout(() => {
            dateOptions.style.display = 'block';
          }, 500); 
        }
        setShowDates(true); 
      };
      
      let lastScrollTop = 0;
      
      const handleWindowScroll = () => {
        const currentScrollTop = window.scrollY;
        const dateOptions = document.getElementById('date-options');
      
        if (currentScrollTop < lastScrollTop && dateOptions) {
            setShowDates(true);
          dateOptions.style.display = 'block';
        } else if (dateOptions) {
            setShowDates(false);
          dateOptions.style.display = 'none';
        }
      
        lastScrollTop = currentScrollTop;
      };

    //   useEffect(() => {
    //     window.addEventListener('scroll', handleWindowScroll);
    //     return () => {
    //       window.removeEventListener('scroll', handleWindowScroll);
    //     };
    //   }, []);
      const resetDates = () => {
        setShowDates(false);
      };

    const redirectToApexDefi = () => {
        window.open('https://apexdefilabs.com/', '_blank');
    }
    const redirectToDefiAssets = () => {
        window.open('https://defiassetsllc.com/', '_blank');
    }
    const redirectToCryptoAssetrating = () => {
        window.open('https://cryptoassetratings.com/', '_blank');
    }
    const redirectToCbw = () => {
        window.open('https://vivasvatrevolutions.com/', '_blank');
        // window.open('https://cryptobusinessworld.com/', '_blank');
    }
    const redirectToFacebook = () => {
        window.open('https://www.facebook.com/pramod.attarde.3', '_blank');
    }
    const redirectToLinkedin = () => {
        window.open('https://www.linkedin.com/in/pramodblockchain/', '_blank');
    }
    const redirectToTwitter = () => {
        window.open('https://twitter.com/AttardePramod', '_blank');
    }
    return (
        <>
        <div className="home">
            <div className="home__top">
                {/* <img className="home__top__banner" src={banner}></img> */}
                 <div className="home__top__content">
                    <div className="profile-section">
                        {/* <h1>TRANSFORMING GLOBAL<br/>
                            <span className="highlight">CAPITAL MARKET</span>
                        </h1> */}
                        <img style={{width:'53%',marginBottom:'15px'}} src={transformglobal} alt="text" />
                        <div className="profile-info">  
                            <img src={promodePic} alt="Pramod Attarde" className="profile-image" />
                    <div className="profile-details">
                        <h2>Pramod Attarde</h2>
                        <p>Chief AI Innovation Officer | <span className="highlight">Visionary Entrepreneur</span> | Speaker & Innovation Evangelist</p>
                        <div className="social-links">
                            <button  className="social-iconss" onClick={redirectToFacebook}>
                                <img src={facebook} alt="" />
                            </button>
                            <button  className="social-iconss" onClick={redirectToTwitter}>
                                <img src={twitter} alt="" />
                            </button>
                            <button  className="social-iconss" onClick={redirectToLinkedin}>
                                <img src={linkedin} alt="" />
                            </button>
                        </div>
                    </div>
                        </div>
                    </div>
                </div>

                <div className="schedule-meeting">
                <button class="schedule-meeting__button" onClick={resetDates}>
                    <span class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" viewBox="0 0 24 24">
                        <path d="M19 4h-1V2h-2v2H8V2H6v2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm0 16H5V9h14v11zm-3-9H8v2h8v-2z"/>
                        </svg>
                    </span> <span class="text">Schedule Meeting with Pramod</span>
                </button>
                {/* <button className="schedule-meeting__button">Schedule Meeting with Pramod</button> */}
                <div className="schedule-meeting__options" id='date-options'>
                    <a href="https://calendly.com/pramoda-apexdefilabs/business-partnership-opportunities-1?month=2025-01&date=2025-01-14" target="_blank">14th January</a>
                    <a href="https://calendly.com/pramoda-apexdefilabs/business-partnership-opportunities-2?month=2025-01&date=2025-01-20" target="_blank">20th January</a>
                    <a href="https://calendly.com/pramoda-apexdefilabs/business-partnership-opportunities-2?month=2025-01&date=2025-01-21" target="_blank">21th January</a>
                    <a href="https://calendly.com/pramoda-apexdefilabs/business-partnership-opportunities-2?month=2025-01&date=2025-01-22" target="_blank">22th January</a>
                    {/* <a href="https://calendly.com/pramoda-apexdefilabs/4th-aug-business-opportunity-with-pramod-attarde" target="_blank">4th August</a>
                    <a href="https://calendly.com/pramoda-apexdefilabs/5th-aug-business-opportunity-with-pramod-attarde" target="_blank">5th August </a> */}
                </div>
                </div>
            </div>
            <div className="home__about">
                {/* About Title and Text */}
                <div className="home__about__content">
                    <h1 className="home__about__title">About Me</h1>
                    <p className="home__about__text">
                    <b>Pramod Attarde</b> serves as the CEO of <b>Apex Defi Labs Inc.,</b> an innovative FINTECH firm headquartered in New York. In this role, he spearheads digital innovation, challenging traditional norms within Global Capital Markets. Renowned for his dynamic speaking prowess, Pramod has the remarkable ability to elucidate intricate technological concepts for non-technical audiences. With a wealth of 28+ years in the tech entrepreneurship realm, he passionately strives to revolutionize the Global Capital Market, making it more accessible for Small and Medium Businesses.His prominence as a speaker is evident as he was invited to address one of the largest capital market conferences globally, held in Las Vegas. At this distinguished event, attended by over 9000 finance professionals across three days, Pramod shared his insights. Recently, he had the privilege to speak at the Government Blockchain Association in Washington DC, engaging in discussions centered around the "Future of Money." His commitment to spreading knowledge on the democratization of capital markets and the transformative power of blockchain technology is evidenced by his engagements at various chambers of commerce, educational institutions, and business gatherings.
                    </p>
                </div>

                {/* Full-Width Image */}
                <img
                    className="home__about__image"
                    src={AboutImage}
                    alt="About me visual"
                />
            </div>
           
                {/* <img className="home__bio__iot-banner" src={iotBanner}></img>
                <div className="home__bio__card">
                    <img className="home__bio__card__pic" src={promodeSidePic}></img>
                    <span className="home__bio__card__description">
                        <b>Pramod</b> extends his expertise by offering <b>consultation to executives, trade associations, governments, and investors in the United States</b>, particularly on the subject of <b>blockchain technology.</b> Notably, he actively contributes to the legislative landscape by assisting the State of New Jersey in drafting essential bills.
                    </span>
                    <span className="home__bio__card__description">
                        Beyond his professional endeavors, Pramod is the visionary behind the Non-Profit New Jersey Blockchain Council, an entity dedicated to disseminating education and awareness about Blockchain Technology. Additionally, he serves as the <b>Director of the "Attarde Foundation."</b> During the second wave of the pandemic, the Attarde Family, through the foundation, generously donated ventilators and oxygen concentrators to India. The foundation's impact has grown exponentially, reaching more than 15,000 individuals in India. Remarkably, the <b>Attarde Foundation is officially recognized as a Certifying Organization for the US President's Volunteer Service Award.</b>
                    </span>
                </div>
                <span className="home__bio__follow-me" onClick={redirectToLinkedin}>
                    <span className="home__bio__follow-me__text">Follow me</span>
                    <img className="home__bio__follow-me__icon" src={followIcon}></img>
                </span> */}

               
                {/* <div className='home__bio__linkedin-badge'>
                    <div className='home__bio__linkedin-badge__head'>
                        <img className="home__bio__linkedin-badge__head__pic" src={linkedinFillIcon}></img>
                    </div>
                    <div className='home__bio__linkedin-badge__body'>
                        <div className='linkedInPramode'>
                        <div>
                        <img className="home__bio__linkedin-badge__body__pic" src={promodePic} width="90"></img>
                         </div>
                        </div>
                        <span className='home__bio__linkedin-badge__body__name'>Pramod A.</span>
                        <span className='home__bio__linkedin-badge__body__description'>Transforming Global Capital Market, Speaker, Innovation Evangelist, Defi, Web3</span>
                        <span className='home__bio__linkedin-badge__body__comp-name'>Apex Defi Labs</span>
                        <span className='home__bio__linkedin-badge__body__view-profile' onClick={redirectToLinkedin}>View profile</span>
                    </div>
                </div> */}
            
            
            
            <div className="expertise-section">
      <div className="hexagon-container">
        <div className="hexagon">
          <img
            src={hexa} 
            alt="Presentation"
          />
        </div>
      </div>
      <div className="text-container">
        <h1 className="expertise-title">e<span style={{color:'#DC85B0'}}>x</span>pertise</h1>
        <p className="expertise-text">
          Pramod extends his expertise by offering <b>consultation</b> to
          executives, trade associations, governments, and investors in the
          United States, particularly on the subject of{" "}
          <b>blockchain technology</b>. Notably, he actively contributes to the
          legislative landscape by assisting the State of New Jersey in
          drafting essential bills.
        </p>
        <p className="expertise-text">
          Beyond his professional endeavors, Pramod is the visionary behind the
          Non-Profit <b>New Jersey Blockchain Council</b>, an entity dedicated
          to disseminating education and awareness about Blockchain Technology.
          Additionally, he serves as the Director of the <b>Attarde Foundation</b>.
          During the second wave of the pandemic, the Attarde Family, through
          the foundation, generously donated ventilators and oxygen
          concentrators to India. The foundation's impact has grown
          exponentially, reaching more than 15,000 individuals in India.
          Remarkably, the <b>Attarde Foundation</b> is officially recognized as
          a Certifying Organization for the US President's Volunteer Service
          Award.
        </p>

        <div className="new-schedule-meeting">
                <button class="new-schedule-meeting__buttonn">
                    <span class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="auto" fill="white" viewBox="0 0 24 24">
                        <path d="M19 4h-1V2h-2v2H8V2H6v2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm0 16H5V9h14v11zm-3-9H8v2h8v-2z"/>
                        </svg>
                    </span> <span class="text">Schedule Meeting with Pramod</span>
                </button>
                
                <div className="new-schedule-meeting__options">
                    <a href="https://calendly.com/pramoda-apexdefilabs/business-partnership-opportunities?month=2025-01&date=2025-01-10" target="_blank">10th January</a>
                    <a href="https://calendly.com/pramoda-apexdefilabs/business-partnership-opportunities?month=2025-01&date=2025-01-11" target="_blank">11th January</a>
                   
                </div>
                </div>      
      </div>
    </div>
  </div>

  <div className="home__bio">
  <div class="home_bio__section">
    <div class="home_bio__section__heading">
      Social
    </div>
    <div class="home_bio__section__content">
     <div className='home__bio__linkedin-badge'>
                <div className='home__bio__linkedin-badge__head'>
                        <img className="home__bio__linkedin-badge__head__pic" src={linkedinFillIcon}></img>
                    </div>
                <div className="linkedin-profile">
                <div className="profile-header">
                    <img
                        src={linkedInBackground}
                    alt="Background"
                    className="background-image"
                    />
                </div>
                <div className="profile-content">
                    <div className="left-section">
                    <img
                        src={promodePic}
                        alt="Profile"
                        className="profile-picture"
                    />
                    </div>
                    <div className="right-section">
                    <h2>Pramod Attarde</h2>
                    <p>
                        Chief AI Innovation Officer, CEO. Transforming Global Capital Market,
                        Speaker, Innovation Evangelist, DeFi, Blockchain, AI/ML, Web3, Gen AI
                    </p>
                    <p onClick={redirectToCbw} className="company-namee">Vivasvat Revolutions Limited</p>

                    <div className='home__bio__linkedin-badge__body'>
                    <span className='home__bio__linkedin-badge__body__view-profile' onClick={redirectToLinkedin}>View profile</span>
                    </div>
                    </div>
                  
                </div>
                    
                </div>
                </div>
                </div>
                </div>
                <div class="home_bio__section">
    <div class="home_bio__section__heading">
      Video
    </div>
    <div className="home_bio__section__content">
            <div className="home__video">
            
                <video className="home__video__video" controls poster={pramodThumbnail}>
                    <source src={meetPromodAttarde} type="video/mp4" />
                </video>
                <div className="home__video__text">
                    <span className="home__video__text__title">Meet Pramod Attarde: Transforming Global Capital Markets and Blockchain Innovation</span>
                    {/* <span className="home__video__text__description">
                        In this captivating video, we introduce you to Pramod Attarde, a visionary leader who is making waves in the world of finance and blockchain technology. Pramod serves as the CEO of Apex Defi Labs Inc., a forward-thinking FINTECH firm headquartered in the heart of New York City.
                    </span> */}
                </div>
            </div>
            </div>
            </div>
            </div>

            
            <div className="home__our-companies">
                {/* <img className="home__our-companies__banner" src={tallModernBuilding}></img> */}
                <div className="home__our-companies__content">
                     <h1 className="home__our-companies__content__head"><span style={{fontSize:'3rem'}}>Our</span><br/>
                            <span style={{color:'#782057'}} >Companies</span>
                        </h1>
                    <div className="card-deck home__our-companies__content__companies">
                        <div className="card home__our-companies__content__companies__card">
                            <img src={apexDefiLabs} className="home__our-companies__content__companies__card__img" alt="Card image cap" />
                            <div className="card-body card home__our-companies__content__companies__card__card-body">
                                <span className="home__our-companies__content__companies__card__card-body__description">A premier destination for cutting-edge decentralized finance solutions. <b> At Apex Defi Labs,</b> we are passionate about leveraging the power of blockchain technology to drive innovation and revolutionize the financial industry. Our team of experienced professionals brings together a wealth of knowledge in blockchain development, finance, and investment to create innovative, secure, and transparent financial products and services for our clients.</span>
                                <span className="home__our-companies__content__companies__card__card-body__btn" onClick={redirectToApexDefi}>
                                    {/* <img className="home__our-companies__content__companies__card__card-body__btn__icon" src={webIcon}></img> */}
                                    <span className="home__our-companies__content__companies__card__card-body__btn__text">Visit website</span>
                                </span>
                            </div>
                        </div>
                        <div className="card home__our-companies__content__companies__card">
                            <img src={defiAssets} className="home__our-companies__content__companies__card__img" alt="Card image cap" />
                            <div className="card-body card home__our-companies__content__companies__card__card-body">
                                <span className="home__our-companies__content__companies__card__card-body__description"><b>At DeFi Assets LLC,</b> our expertise reflects in the tokenization of real-world assets, offering businesses the opportunity to convert their tangible assets into digital tokens. Through our innovative tokenization solutions, we enable the unlocking of liquidity, enhance accessibility, and facilitate fractional ownership of assets. Our goal is to revolutionize the way assets are represented and traded, providing greater flexibility and opportunities for businesses and investors alike.</span>
                                <span className="home__our-companies__content__companies__card__card-body__btn" onClick={redirectToDefiAssets}>
                                    {/* <img className="home__our-companies__content__companies__card__card-body__btn__icon" src={webIcon}></img> */}
                                    <span className="home__our-companies__content__companies__card__card-body__btn__text">Visit website</span>
                                </span>
                            </div>
                        </div>
                        <div className="card home__our-companies__content__companies__card">
                            <img src={carLogo} className="home__our-companies__content__companies__card__img" alt="Card image cap" />
                            <div className="card-body card home__our-companies__content__companies__card__card-body">
                                <span className="home__our-companies__content__companies__card__card-body__description">We provide investors with valuable insights into the quality, risk, and potential of crypto assets through our comprehensive and dependable rating services. Our aim is to equip investors with the necessary tools to make informed decisions in the ever-changing crypto market. With our rating services, investors can confidently navigate the dynamic landscape of cryptocurrencies and make sound investment choices.</span>
                                <span className="home__our-companies__content__companies__card__card-body__btn" onClick={redirectToCryptoAssetrating}>
                                    {/* <img className="home__our-companies__content__companies__card__card-body__btn__icon" src={webIcon}></img> */}
                                    <span className="home__our-companies__content__companies__card__card-body__btn__text">Visit website</span>
                                </span>
                            </div>
                        </div>
                        <div className="card home__our-companies__content__companies__card">
                            <img  style={{width:'50%'}} src={cbwLogo} className="home__our-companies__content__companies__card__img" alt="Card image cap" height="49" width="200" />
                            <div className="card-body card home__our-companies__content__companies__card__card-body">
                                <span className="home__our-companies__content__companies__card__card-body__description"><b>Vivasvat Revolutions</b> Limited is reshaping real estate investment through technology-driven solutions. Our focus is on democratizing real estate by enabling retail investors in India to participate seamlessly in property investments. By introducing fractional ownership and leveraging blockchain for enhanced security and transparency, Vivasvat Revolutions Limited is making real estate accessible, secure, and more efficient than ever before.</span>
                                <span className="home__our-companies__content__companies__card__card-body__btn" onClick={redirectToCbw}>
                                    {/* <img className="home__our-companies__content__companies__card__card-body__btn__icon" src={webIcon}></img> */}
                                    <span className="home__our-companies__content__companies__card__card-body__btn__text">Visit website</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  

            
               
            <div className="home__achievements">
                <div className="home__achievements__left">
                    <h1 className="home__achievements__left__some-of-his">Some of his<br/>
                            <span className="home__achievements__left__achievements"  style={{color:'#782057'}} >Achievements</span>
                        </h1>
                    <span className="home__achievements__left__description"><span style={{color:'#782057',fontWeight:'bold'}}>Pramod Attarde,</span> as CEO of Apex Defi Labs Inc., leads fintech innovation in global capital markets. With over 28 years in tech entrepreneurship, his dynamic communication skills simplify intricate technological concepts.</span>
                    <span className="home__achievements__left__description">Beyond corporate success, Pramod's commitment to social impact is evident through the Attarde Foundation and the Non-Profit New Jersey Blockchain Council, which he founded. During the pandemic, the foundation generously donated ventilators and oxygen concentrators, impacting 15,000 individuals in India. It's officially recognized for the US President's Volunteer Service Award. Pramod's multifaceted roles as a CEO, philanthropist, and blockchain advocate leave a lasting mark on industries and communities alike.</span>
                    {/* <div className="home__achievements__left__social-media">
                        <span className="home__achievements__left__social-media__text">Follow me</span>
                        <span className="home__achievements__left__social-media__icons">
                            <img className="home__achievements__left__social-media__icons__icon" onClick={redirectToFacebook} src={facebookIcon}></img>
                            <img className="home__achievements__left__social-media__icons__icon" onClick={redirectToLinkedin} src={linkedinIcon}></img>
                            <img className="home__achievements__left__social-media__icons__icon" onClick={redirectToTwitter} src={twitterIcon}></img>
                        </span>
                    </div> */}

                    <div className="home__achievements__right">

                        <Slider {...settings}>
                            <div className="card home__achievements__right__card ">
                                <img src={speakerAtBiggestCapital} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description block text-700 ">Speaker at Biggest Capital Market Conference “<span style={{color:'#782057',fontWeight:'bold'}}>SFVegas</span>” in 2020 at Las Vegas</span>
                                {/* <span className="home__achievements__right__card__year ">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>
                            <div className="card home__achievements__right__card ">
                                <img src={meetingWithAssemblyman} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description">Meeting with Assemblyman <span style={{color:'#782057',fontWeight:'bold'}}>Robert Korbenchik</span>, New Jersey at Conference explained on Blockchain.</span>
                                {/* <span className="home__achievements__right__card__year">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>
                            <div className="card home__achievements__right__card ">
                                <img src={mrPromodeMetHon} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description">Mr. Pramod met Hon.<span style={{color:'#782057',fontWeight:'bold'}}> Gadakariji</span> during his visit to India to discussing about Blockchain.</span>
                                {/* <span className="home__achievements__right__card__year">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>

                            <div className="card home__achievements__right__card ">
                                <img src={GlobalEntrepreneurs} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description">Speaking at <span style={{color:'#782057',fontWeight:'bold'}}>Global Entrepreneurs Conclave 2025</span>  in India on "Silicon Valley to Bharat A Borderless Tech Ecosystem"</span>
                            </div>

                            <div className="card home__achievements__right__card ">
                                <img src={pramodSlider} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description">Mr.Pramod as a Speaker for The Future of Money, Governance, and the Law at <span style={{color:'#782057',fontWeight:'bold'}}>Government Blockchain Association</span> ,Washington DC - 2023</span>
                                {/* <span className="home__achievements__right__card__year">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>
                            <div className="card home__achievements__right__card ">
                                <img src={newSliderImg2} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description">Mr.Pramod with <span style={{color:'#782057',fontWeight:'bold'}}>Mr.Ragunath Mashelkar</span> Mr.Ragunath Mashelkar at Garje Marathi Global Excellence Summit 2023</span>
                                {/* <span className="home__achievements__right__card__year">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>
                            <div className="card home__achievements__right__card ">
                                <img src={newSliderImg3} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description">At the Vegas Conference with US Congressman <span style={{color:'#782057',fontWeight:'bold'}}>Raja Krishnamoorthi</span> in Washington, DC</span>
                                {/* <span className="home__achievements__right__card__year">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>
                            <div className="card home__achievements__right__card ">
                                <img src={newSliderImg4} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description">At the GMG Conference with the US Congressman, <span style={{color:'#782057',fontWeight:'bold'}}>Shri Thanedar </span></span>
                                {/* <span className="home__achievements__right__card__year">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>
                            <div className="card home__achievements__right__card ">
                                <img src={sliderImg5} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description">Speaker at the <span style={{color:'#782057',fontWeight:'bold'}}>Government Blockchain Association</span> Government Blockchain Association in Washington, DC</span>
                                {/* <span className="home__achievements__right__card__year">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>
                            <div className="card home__achievements__right__card ">
                                <img src={newSliderImg8} className="home__achievements__right__card__img pramodeWithAddrate" />
                                <span className="home__achievements__right__card__description">At the Consensus Conference With an <span style={{color:'#782057',fontWeight:'bold'}}>SEC Commissioner</span> in Texas</span>
                            </div>
                            <div className="card home__achievements__right__card ">
                                <img src={newSliderImg1} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description">Food Donation from the <span style={{color:'#782057',fontWeight:'bold'}}> Attarde Foundation</span>
                                </span>
                                {/* <span className="home__achievements__right__card__year">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>
                            <div className="card home__achievements__right__card ">
                                <img src={newSliderImg7} className="home__achievements__right__card__img" />
                                <span className="home__achievements__right__card__description"><span style={{color:'#782057',fontWeight:'bold'}}> Attarde Foundation</span> generously donated ventilator and oxygen concentrators in India during COVID second wave</span>
                                {/* <span className="home__achievements__right__card__year">
                                        <span className="home__achievements__right__card__year__text">Year 2019</span>
                                    </span> */}
                            </div>
                           
                        </Slider>
                </div>
                </div>
                
            </div>



            <div className="page-container">
      <main className="main-content">
        <div className="hero-section">
          <div className="text-content">
            <h1>Have something in mind?</h1>
            <div className="profile-container">
              <img 
                src={promodePic}
                alt="Profile"
                className="profile-image"
              />
              <p className="tagline">
                let's build it together.
              </p>
            </div>
          </div>

          <button className="cta-button" onClick={scrollToTop}>
            Get in Touch
          </button>
        </div>
      </main>

      <footer className="footer">
        <div className="footer-content">
          <p className="copyright">
            © Copyright 2025 pramodattarde.com. All rights reserved.
          </p>
          
          <div className="social-iconss">
        <button  className="social-iconss" onClick={redirectToFacebook}>
                <img src={facebook} alt="" />
            </button>
             <button  className="social-iconss" onClick={redirectToTwitter}>
                <img src={twitter} alt="" />
            </button>
            <button  className="social-iconss" onClick={redirectToLinkedin}>
                <img src={linkedin} alt="" />
        </button>
          </div>
        </div>
      </footer>
    </div>
 </>

    );
}
export default Home;